export default {
  markers: [
    {
      store_id: "113",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762776>021 276 2776" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP U7, MALL OF THE NORTH, CNR R81 AND N1 BENDOR EXTENSION 99, POLOKWANE, LIMPOPO, 0699",
      store_city: "Polokwane (Limpopo)",
      positions: {
        lat: -23.87489,
        lng: 29.50855,
      },
      store_title: "Sunglass Hut Woolworths Mall of the North",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2776 <br/> <br/> <strong>Address</strong> <br/> SHOP U7, MALL OF THE NORTH, CNR R81 AND N1 BENDOR EXTENSION 99," +
          " POLOKWANE, LIMPOPO, 0699",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "112",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762815>021 276 2815" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 55, MAIN ROAD, WALMER PARK SHOPPING CENTRE, WALMER PARK, PORT ELIZABETH, 6001",
      store_city: "Port Elizabeth",
      positions: {
        lat: -33.979738,
        lng: 25.559446,
      },
      store_title: "Sunglass Hut Woolworths Walmer Park",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2815 <br/> <br/> <strong>Address</strong> <br/> SHOP 55, MAIN ROAD, WALMER PARK SHOPPING CENTRE," +
          " WALMER PARK, PORT ELIZABETH, 6001",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "111",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762768>021 276 2768" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP U10. CNR OF DR. V/D MERWE & ZAMBEZI DRIVE, KOLLONADE SHOPPING CENTRE, MONTANA PARK, PRETORIA, 0159",
      store_city: "Pretoria",
      positions: {
        lat: -25.681748,
        lng: 28.272984,
      },
      store_title: "Sunglass Hut Woolworths Montana",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2768 <br/> <br/> <strong>Address</strong> <br/> SHOP U10. CNR OF DR. V/D MERWE & ZAMBEZI DRIVE," +
          " KOLLONADE SHOPPING CENTRE, MONTANA PARK, PRETORIA, 0159",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "2222",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762823>021 276 2823" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 26, CNR RIETFONTEIN RD & BENTEL AVE, EASTRAND MALL,  BOKSBURG, GAUTENG,  1459",
      store_city: "Johannesburg",
      positions: {
        lat: -26.18183,
        lng: 28.23862,
      },
      store_title: "Sunglass Hut Woolworths East Rand",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2823 <br/> <br/> <strong>Address</strong> <br/> SHOP 26, CNR RIETFONTEIN RD & BENTEL AVE," +
          " EASTRAND MALL,  BOKSBURG, GAUTENG,  1459",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1477",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762853>021 276 2853" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 103-105, CNR HENRY STREET, LOCH LOGAN WATERFRONT, WESTDENE, BLOEMFONTEIN, 9301",
      store_city: "Bloemfontein",
      positions: {
        lat: -29.1156,
        lng: 26.2100,
      },
      store_title: "Sunglass Hut Woolworths Loch Logan",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2853 <br/> <br/> <strong>Address</strong> <br/> SHOP 103-105, CNR HENRY STREET," +
          " LOCH LOGAN WATERFRONT, WESTDENE, BLOEMFONTEIN, 9301",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "342",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762817>021 276 2817" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP U15/L31 CNR SIMON VERMOOTEN & LYNNWOOD ROAD, THE GROVE SHOPPING CENTRE, LYNNWOOD, PRETORIA, 0043",
      store_city: "Pretoria",
      positions: {
        lat: -25.766422,
        lng: 28.324835,
      },
      store_title: "Sunglass Hut Woolworths The Grove",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2817 <br/> <br/> <strong>Address</strong> <br/> SHOP U15/L31 CNR SIMON VERMOOTEN & LYNNWOOD ROAD," +
          " THE GROVE SHOPPING CENTRE, LYNNWOOD, PRETORIA, 0043",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1476345",
      store_name:
          "<strong>Tel. Number:</strong> <br/><a href=tel:0212762818>021 276 2818" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP G9 CNR WITKOPPEN & WILLIAM NICOL RD., FOURWAYS MALL, FOURWAYS, JOHANNESBURG, GAUTENG, 2055",
      store_city: "Johannesburg",
      positions: {
        lat: -26.0199572,
        lng: 28.0064061,
      },
      store_title: "Sunglass Hut Woolworths Fourways",
      store_address:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762818>021 276 2818" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP G9 CNR WITKOPPEN & WILLIAM NICOL RD., FOURWAYS MALL, FOURWAYS, JOHANNESBURG, GAUTENG, 2055",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 399,
    },
    {
      store_id: "1460",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762753>021 276 2753" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP L33B, 131 KELLNER STR, MIMOSA MALL GROUND FLOOR, BRANDWAG, BLOEMFONTEIN, 9301",
      store_city: "Bloemfontein",
      positions: {
        lat: -29.1092563,
        lng: 26.2012976,
      },
      store_title: "Sunglass Hut Mimosa",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2753<br/> <br/> <strong>Address</strong> <br/> SHOP L33B, 131 KELLNER" +
          " STR, MIMOSA MALL GROUND FLOOR, BRANDWAG, BLOEMFONTEIN, 9301",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1663",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762772>021 276 2772" +
          " </a><br> <br/> <br/> <strong>Address</strong> <br/><br/> <br/> <strong>Address</strong> <br/> SHOP 1, V&A WATERFRONT, V&A WHARF SHOPPING CENTRE, V&A WATERFRONT, CAPE TOWN, 8001",
      store_city: "Cape Town",
      positions: {
        lat: -33.904,
        lng: 18.419298,
      },
      store_title: "Sunglass Hut V&A Boatshed",
      store_address:
          "<strong>Tel. Number:</strong> <br/>  021 276 2772<br/> <br/> <strong>Address</strong> <br/>SHOP 1, V&A WATERFRONT," +
          " VICTORIA WHARF SHOPPING CENTRE, CAPE TOWN, 8001",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1488",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762705>021 276 2705" +
          " </a><br/> <br/> <strong>Address</strong> <br/><br/> <br/> <strong>Address</strong> <br/> SHOP 7206, V&A WATERFRONT LINK MALL, V&A WARF SHOPPING CENTRE, WATERFRONT, CAPE TOWN, 8001",
      store_city: "Cape Town",
      positions: {
        lat: -33.904,
        lng: 18.419498,
      },
      store_title: "Sunglass Hut V&A Waterfront",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2705 <br/> <br/> <strong>Address</strong> <br/> SHOP 7206, V&A WATERFRONT," +
          " VICTORIA WHARF SHOPPING CENTRE, CAPE TOWN, 8001",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "F110",
      store_name: "",
      store_city: "Cape Town",
      positions: {
        lat: -33.904,
        lng: 18.419398,
      },
      store_title: "Sunglass Hut Woolworths V&A",
      store_address:
          "<strong>Tel. Number:</strong> <br/>  021 276 2706 <br/> <br/> <strong>Address</strong> <br/>  SHOP 6138, V&A WATERFRONT" +
          " EXTENSION, V&A WARF SHOPPING CENTRE, GROUND LEVEL, V&A WATERFRONT, CAPE TOWN, 8002",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1437",
      store_name:
          "<strong>Tel. Number:</strong> <br/><a href=tel:0212762721>021 276 2721" +
          " </a><br/> <br/> <strong>Address</strong> <br/> GROUND FLOOR, BLOCK D, BLACK RIVER PARK NORTH, FIR ROAD, OBSERVATORY, 7945",
      store_city: "Cape Town",
      positions: {
        lat: -33.9343369,
        lng: 18.4716493,
      },
      store_title: "Sunglass Hut Black River Park Outlet",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2721 <br/> <br/> <strong>Address</strong> <br/> GROUND FLOOR, BLOCK D," +
          " BLACK RIVER PARK NORTH, FIR ROAD, OBSERVATORY, 7945",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1455",
      store_name:
          "<strong>Tel. Number:</strong> <br/><a href=tel:0212762724>021 276 2724" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 93, CENTURY BLVD, CANAL WALK SHOPPING CENTRE, LOWER LEVEL,  MILNERTON, CENTURY CITY, CAPE TOWN, 7441",
      store_city: "Cape Town",
      positions: {
        lat: -33.893273,
        lng: 18.511364,
      },
      store_title: "Sunglass Hut Canal Walk",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2724 <br/> <br/> <strong>Address</strong> <br/> SHOP 93, CENTURY BLVD," +
          " CANAL WALK SHOPPING CENTRE, LOWER LEVEL, MILNERTON, CENTURY CITY, CAPE TOWN, 7441",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1466",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762723>021 276 2723" +
          " </a><br/> <br/> <strong>Address</strong> <br/> KIOSK KU2, CENTURY BVLD, CANAL WALK SHOPPING CENTRE, MILNERTON, CENTURY CITY, CAPE TOWN, 7441",
      store_city: "Cape Town",
      positions: {
        lat: -33.893273,
        lng: 18.511264,
      },
      store_title: "Sunglass Hut Canal Walk Kiosk",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2723 <br/> <br/> <strong>Address</strong> <br/> KIOSK KU2, CENTURY BVLD," +
          " CANAL WALK SHOPPING CENTRE, MILNERTON, CENTURY CITY, CAPE TOWN, 7441",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "21",
      store_name: "",
      store_city: "Cape Town",
      positions: {
        lat: -33.893273,
        lng: 18.511464,
      },
      store_title: "Sunglass Hut Woolworths Canal Walk",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2720 <br/> <br/> <strong>Address</strong> <br/> SHOP 267LL, CENTURY BLVD," +
          " CANAL WALK SHOPPING CENTRE, MILNERTON, CENTURY CITY, CAPE TOWN, 7441",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1664",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762769>021 276 2769" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP G329. TABLE BAY MALL, BERKSHIRE BLVD . CAPE FARMS, CAPE TOWN 7441",
      store_city: "Cape Town",
      positions: {
        lat: -33.785489,
        lng: 18.47921,
      },
      store_title: "Sunglass Hut Table Bay Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2769 <br/> <br/> <strong>Address</strong> <br/> SHOP G329. TABLE BAY MALL," +
          " BERKSHIRE BLVD . CAPE FARMS, CAPE TOWN, 7441",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1453",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762729>021 276 2729" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP UL569, WILLIE VAN SCHOOR AVE, UPPER LEVEL, TYGERVALLEY MALL, 7530",
      store_city: "Cape Town",
      positions: {
        lat: -33.8739421,
        lng: 18.6347341,
      },
      store_title: "Sunglass Hut Tygervalley",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2729 <br/> <br/> <strong>Address</strong> <br/> SHOP UL569, WILLIE VAN" +
          " SCHOOR AVE, UPPER LEVEL, TYGERVALLEY MALL, 7530",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "22",
      store_name: "",
      store_city: "Cape Town",
      positions: {
        lat: -33.8739421,
        lng: 18.6346341,
      },
      store_title: "Sunglass Hut Woolworths Tygervalley",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2714 <br/> <br/> <strong>Address</strong> <br/> SHOP 504, CNR BILL BEZUIDENHOUT & HUME STR" +
          " TYGERVALLEY SHOPPING CENTRE, BELLVILLE, CAPE TOWN, 7530",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "222",
      store_name: "",
      store_city: "Pretoria",
      positions: {
        lat: -25.82348,
        lng: 28.31391,
      },
      store_title: "Sunglass Hut Woolworths Woodlands",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2850 <br/> <br/> <strong>Address</strong> <br/> SHOP 32, CNR OF GARSFONTEIN & DEVILLEBOIS DRIVE" +
          ", WOOLANDS BOULEVARD, WOODHILL,  PRETORIA, 0077",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1540",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762731>021 276 2731" +
          " </a><br/> <br/> <strong>Address</strong> <br/> KIOSK KU11 , CNR OKAVANGO & DEBRON RD, CAPE GATE SHOPPING CENTRE, CAPE GATE, BRAKENFELL, 7650",
      store_city: "Cape Town",
      positions: {
        lat: -33.8524101,
        lng: 18.697207,
      },
      store_title: "Sunglass Hut Cape Gate Shopping Centre",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2731 <br/> <br/> <strong>Address</strong> <br/> KIOSK KU11 ,CNR OKAVANGO &" +
          " DEBRON RD, CAPE GATE SHOPPING CENTRE, CAPE GATE, BRAKENFELL, 7650",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1526",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762855>021 276 2855" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 43, CECILLA STREET, PAARL MALL SHOPPING CENTRE, PAARL SOUTH, 7624",
      store_city: "Cape Town",
      positions: {
        lat: -33.7668,
        lng: 18.9661,
      },
      store_title: "SUNGLASS HUT WOOLWORTHS PAARL",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2855 <br/> <br/> <strong>Address</strong> <br/> SHOP 43, CECILLA STREET," +
          " PAARL MALL SHOPPING CENTRE, PAARL SOUTH, 7624",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1532",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762735>021 276 2735" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 20, CAPE TOWN INTERNATIONAL AIRPORT, DOMESTIC ARRIVALS (LANDSIDE), CAPE TOWN, 7525",
      store_city: "Cape Town",
      positions: {
        lat: -33.971463,
        lng: 18.6021851,
      },
      store_title: "Sunglass Hut CTIA Domestic Landside",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2735 <br/> <br/> <strong>Address</strong> <br/> SHOP 20, CAPE TOWN" +
          " INTERNATIONAL AIRPORT, DOMESTIC ARRIVALS (LANDSIDE), CAPE TOWN, 7525",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1534",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762736>021 276 2736" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SH 205, CAPE TOWN INTERNATIONAL AIRPORT, DOMESTIC DEPARTURES (AIRSIDE), CAPE TOWN, 7525",
      store_city: "Cape Town",
      positions: {
        lat: -33.971463,
        lng: 18.6022851,
      },
      store_title: "Sunglass Hut CTIA Domestic Airside",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2736 <br/> <br/> <strong>Address</strong> <br/> SHOP 205, CAPE TOWN" +
          " INTERNATIONAL AIRPORT, DOMESTIC DEPARTURES (AIRSIDE), CAPE TOWN, 7525",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "23",
      store_name: "",
      store_city: "Cape Town",
      positions: {
        lat: -33.971463,
        lng: 18.6020851,
      },
      store_title: "Sunglass Hut CTIA Duty Free Store",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2734 <br/> <br/> <strong>Address</strong> <br/> POD 3, DUTY FREE INT." +
          " DEPT., CAPE TOWN INTERNATIONAL AIRPORT, 2194",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1489",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762715>021 276 2715" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP F44, DREYER STR, CAVENDISH SQUARE SHOPPING CENTRE, CLAREMONT, CAPE TOWN, 7535",
      store_city: "Cape Town",
      positions: {
        lat: -33.979866,
        lng: 18.464616,
      },
      store_title: "Sunglass Hut Cavendish",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2715 <br/> <br/> <strong>Address</strong> <br/> SHOP F44, DREYER STR," +
          " CAVENDISH SQUARE SHOPPING CENTRE, CLAREMONT, CAPE TOWN, 7535",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "24",
      store_name: "",
      store_city: "Cape Town",
      positions: {
        lat: -33.979866,
        lng: 18.463616,
      },
      store_title: "Sunglass Hut Woolworths Cavendish",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2738 <br/> <br/> <strong>Address</strong> <br/> SHOP G132, DREYER STR," +
          " CAVENDISH SQUARE SHOPPING CENTRE, CLAREMONT, CAPE TOWN, 7535",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1454",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762716>021 276 2716" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP F216, BLUE ROUTE MALL, 16 TOKAI ROAD, TOKAI, 7945",
      store_city: "Cape Town",
      positions: {
        lat: -34.0637478,
        lng: 18.454391,
      },
      store_title: "Sunglass Hut Blue Route",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2716 <br/> <br/> <strong>Address</strong> <br/> SHOP F216, BLUE ROUTE" +
          " MALL, 16 TOKAI ROAD, TOKAI, 7945",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1451",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762726>021 276 2726" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 43, CNR N2 HWY AND R44, SOMERSET MALL, SOMERSET WEST, 7130",
      store_city: "Cape Town",
      positions: {
        lat: -34.082619,
        lng: 18.823421,
      },
      store_title: "Sunglass Hut Somerset",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2726 <br/> <br/> <strong>Address</strong> <br/> SHOP 43, CNR N2 HWY AND" +
          " R44, SOMERSET MALL, SOMERSET WEST, 7130",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1459",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762852>021 276 2852" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 38, CNR N2 HWY AND R44, SOMERSET MALL, SOMERSET WEST, 7130",
      store_city: "Cape Town",
      positions: {
        lat: -34.082719,
        lng: 18.823521,
      },
      store_title: "Sunglass Hut Woolworths Somerset",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2852 <br/> <br/> <strong>Address</strong> <br/> SHOP 38, CNR N2 HWY AND" +
          " R44, SOMERSET MALL, SOMERSET WEST, 7130",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1531",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762841>021 276 2841" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP G5 GEORGE AIRPORT, DOMESTIC LANDSIDE KIOSK, GARDEN ROUTE, WESTERN CAPE, 6529",
      store_city: "Cape Town",
      positions: {
        lat: -34.0043643,
        lng: 22.3809737,
      },
      store_title: "Sunglass Hut George Airport Domestic Landside",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2841 <br/> <br/> <strong>Address</strong> <br/> SHOP G5 GEORGE AIRPORT," +
          " DOMESTIC LANDSIDE KIOSK, GARDEN ROUTE, WESTERN CAPE, 6529",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1444",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762839>021 276 2839" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 70, CNR N2 HIGHWAY & KNYSNA RD, GARDEN ROUTE MALL, GEORGE, 6529",
      store_city: "Cape Town",
      positions: {
        lat: -33.9871504,
        lng: 22.5028043,
      },
      store_title: "Sunglass Hut Garden Route",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2839 <br/> <br/> <strong>Address</strong> <br/> SHOP 70, CNR N2 HIGHWAY &" +
          " KNYSNA RD, GARDEN ROUTE MALL,  GEORGE, 6529",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1445",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762854>021 276 2854" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 9, CNR N2 HIGHWAY & KNYSNA RD, GARDEN ROUTE MALL, GEORGE, 6529",
      store_city: "Cape Town",
      positions: {
        lat: -33.98750,
        lng: 22.50252,
      },
      store_title: "Sunglass Hut Woolworths Garden Route",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2854 <br/> <br/> <strong>Address</strong> <br/> SHOP 9, CNR N2 HIGHWAY &" +
          " KNYSNA RD, GARDEN ROUTE MALL,  GEORGE, 6529",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {

      store_id: "2634",
      store_name: "",
      store_city: "Cape Town",
      positions: {
        lat: -34.4146280494,
        lng:19.2052602768,
      },
      store_title: "Sunglass Hut Whale Coast Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2837 <br/> <br/> <strong>Address</strong> <br/> SHOP G22, Whale Coast" +
          " Mall, R43 Main Road Sandbaai, Hermanus, Western Cape, 7200",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "25",
      store_name: "",
      store_city: "Durban",
      positions: {
        lat: -29.7259994,
        lng: 31.0659285,
      },
      store_title: "Sunglass Hut Gateway",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2741 <br/> <br/> <strong>Address</strong> <br/> STORE F270, 1 PALM BVLD, GATEWAY SHOPPING CENTRE" +
          " UMHLANGA RIDGE, NEWTON CENTRE, UMHLANGA, KZN, 4319",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "A803",
      store_name: "",
      store_city: "Durban",
      positions: {
        lat: -29.7259994,
        lng: 31.0658285,
      },
      store_title: "Sunglass Hut Woolworths Gateway",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2718 <br/> <br/> <strong>Address</strong> <br/> SH G089 1 PALM BVLD, GATEWAY SHOPPING CENTRE" +
          " UMHLANGA RIDGE, NEWTON CENTRE, UMHLANGA, KZN, 4319 ",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "26",
      store_name: "",
      store_city: "Durban",
      positions: {
        lat: -29.5835469,
        lng: 30.3782524,
      },
      store_title: "Sunglass Hut Woolworths Midlands",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2752 <br/> <br/> <strong>Address</strong> <br/> SHOP 126 MIDLANDS MALL," +
          " SANCTURY ROAD, MIDLANDS MALL SHOPPING CENTRE, PIETERMARITZBURG, 3201",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "27",
      store_name: "",
      store_city: "Durban",
      positions: {
        lat: -29.7538,
        lng: 31.0645,
      },
      store_title: "Sunglass Hut La Lucia",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2737 <br/> <br/> <strong>Address</strong> <br/> SHOP 3C, WILLIAM CAMPBELL" +
          " DRV, LA LUCIA MALL, LA LUCIA, DURBAN, 4051",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1656",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762709>021 276 2709" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 636 LEONORA DR, DOLPHIN COAST, KWAZULU NATAL, 4399",
      store_city: "Durban",
      positions: {
        lat: -29.5268656,
        lng: 31.2021728,
      },
      store_title: "Sunglass Hut Ballito",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2709 <br/> <br/> <strong>Address</strong> <br/> SHOP 636 LEONORA DR," +
          " DOLPHIN COAST, KWAZULU NATAL, 4399",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1530",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762749>021 276 2749" +
          " </a>KING SHAKA INTERNATIONAL AIRPORT, DOMESTIC AIRSIDE <br/> <br/> <strong>Address</strong> <br/> SHOP DDR3, LA MERCY, DURBAN, KZN, 4301",
      store_city: "Durban",
      positions: {
        lat: -29.6099786,
        lng: 31.1168871,
      },
      store_title: "Sunglass Hut King Shaka Airside Kiosk",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2749 KING SHAKA INTERNATIONAL" +
          " AIRPORT, DOMESTIC AIRSIDE <br/> <br/> <strong>Address</strong> <br/> KING SHAKA INTERNATIONAL AIRPORT, DOMESTIC AIRSIDE SHOP DDR3, LA MERCY, DURBAN, KZN, 4301",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1533",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762750>021 276 2750" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP AR02, KING SHAKA INTERNATIONAL AIRPORT, DOMESTIC ARRIVALS LANDSIDE, LA MERCY, DURBAN, KZN 4301",
      store_city: "Durban",
      positions: {
        lat: -29.6099786,
        lng: 31.1167871,
      },
      store_title: "Sunglass Hut King Shaka Landside Store",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2750 <br/> <br/> <strong>Address</strong> <br/> SHOP AR02, KING SHAKA" +
          " INTERNATIONAL AIRPORT, DOMESTIC ARRIVALS LANDSIDE  , LA MERCY, DURBAN, KZN, 4301",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1648",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762751>021 276 2751" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP U45 INANDA RD WATERFALL, DURBAN, KWAZULU-NATAL 3610",
      store_city: "Durban",
      positions: {
        lat: -29.748104,
        lng: 30.813705,
      },
      store_title: "Sunglass Hut Watercrest Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2751 <br/> <br/> <strong>Address</strong> <br/> SHOP U45 INANDA RD" +
          " WATERFALL, DURBAN, KWAZULU-NATAL 3610",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1651",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762744>021 276 2744" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 104, 50 SANCTURY ROAD, MIDLANDS MALL SHOPPING CENTRE, PIETERMARITZBURG, 3201",
      store_city: "Durban",
      positions: {
        lat: -29.5835469,
        lng: 30.3781524,
      },
      store_title: "Sunglass Hut Midlands Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2744 <br/> <br/> <strong>Address</strong> <br/> SHOP 104, 50 SANCTURY" +
          " ROAD, MIDLANDS MALL SHOPPING CENTRE, PIETERMARITZBURG, 3201",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1657",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762773>021 276 2773" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP U123A, PINEWOOD RD, DURBAN, 4339",
      store_city: "Durban",
      positions: {
        lat: -29.710969,
        lng: 31.054701,
      },
      store_title: "Sunglass Hut Cornubia",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2773 <br/> <br/> <strong>Address</strong> <br/> SHOP U123A, PINEWOOD RD," + " DURBAN, 4339",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1449",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762739>021 276 2739" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP L113, JACK MARTENS DR, THE PAVILION, WESTVILLE, DURBAN, 3630",
      store_city: "Durban",
      positions: {
        lat: -29.8491005,
        lng: 30.9351992,
      },
      store_title: "Sunglass Hut Pavilion Centre",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2739 <br/> <br/> <strong>Address</strong> <br/> SHOP L113, JACK MARTENS" +
          " DR, THE PAVILION, WESTVILLE, DURBAN, 3630",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "A805",
      store_name: "",
      store_city: "Durban",
      positions: {
        lat: -29.8491005,
        lng: 30.9352992,
      },
      store_title: "Sunglass Hut Woolworths Pavilion",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2761 <br/> <br/> <strong>Address</strong> <br/> SHOP 120, JACK MARTENS DR," +
          " THE PAVILION, WESTVILLE, DURBAN, 3630",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1494",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762848>021 276 2848" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP UG23, CNR WESTERN AVENUE & TWO RIVERS ROAD, HEMINGWAYS MALL, EAST LONDON, 5201",
      store_city: "East London",
      positions: {
        lat: -32.9697516,
        lng: 27.8961441,
      },
      store_title: "Sunglass Hut Hemingways",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2848 <br/> <br/> <strong>Address</strong> <br/> SHOP UG23, CNR WESTERN" +
          " AVENUE & TWO RIVERS ROAD, HEMINGWAYS MALL, EAST LONDON, 5201",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1503",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762713>021 276 2713" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 242, THE MALL OF ROSEBANK, 50 BATH AVENUE, ROSEBANK, JOHANNESBURG, 2196",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1465964,
        lng: 28.0413478,
      },
      store_title: "Sunglass Hut Rosebank Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2713 <br/> <br/> <strong>Address</strong> <br/> SHOP 242, THE MALL OF" +
          " ROSEBANK, 50 BATH AVENUE, ROSEBANK, ROSEBANK, JOHANNESBURG, 2196",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "28",
      store_name: "",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1465964,
        lng: 28.0414478,
      },
      store_title: "Sunglass Hut Woolworths Rosebank",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 0212 76 2767‬ <br/> <br/> <strong>Address</strong> <br/> SHOP 215/216,THE MALL OF" +
          " ROSEBANK, 50 BATH AVENUE, ROSEBANK",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1441",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762764>021 276 2764" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP U121, 43 BRADFORD RD, EASTGATE SHOPPING CENTRE, BEDFORDVIEW, GAUTENG, 2007",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1804046,
        lng: 28.1167359,
      },
      store_title: "Sunglass Hut Eastgate ",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2764 <br/> <br/> <strong>Address</strong> <br/> SHOP U121, 43 BRADFORD RD," +
          " EASTGATE SHOPPING CENTRE, BEDFORDVIEW, GAUTENG, 2008",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1458",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762765>021 276 2765" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP U68, 43 BRADFORD RD, EASTGATE SHOPPING CENTRE, LOWER LEVEL, BEDFORDVIEW, GAUTENG, 2007",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1804046,
        lng: 28.1169359,
      },
      store_title: "Sunglass Hut Eastgate Main",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2765 <br/> <br/> <strong>Address</strong> <br/> SHOP U68, 43 BRADFORD RD," +
          " EASTGATE SHOPPING CENTRE, LOWER LEVEL, BEDFORDVIEW, GAUTENG, 2008",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "A799",
      store_name: "",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1804046,
        lng: 28.1168359,
      },
      store_title: "Sunglass Hut Woolworths Eastgate",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2732 <br/> <br/> <strong>Address</strong> <br/> SHOP LDP03A, 43 BRADFORD" +
          " RD, EASTGATE SHOPPING CENTRE, BEDFORDVIEW, GAUTENG, 2008",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1442",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762777>021 276 2777" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP LM142, CHRISTIAN DE WET RD,  CLEARWATER MALL, STRUBENS VLY, ROODEPOORT, JOHANNESBURG, 1735",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1277249,
        lng: 27.906712,
      },
      store_title: "Sunglass Hut Clearwater",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2777 <br/> <br/> <strong>Address</strong> <br/> SHOP LM142, CHRISTIAN DE" +
          " WET RD,  CLEARWATER MALL, STRUBENS VLY, ROODEPOORT, JOHANNESBURG, 1735",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1523",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762779>021 276 2779" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP LM061A, 927 CHRISTIAN DE WEST RD, CLEARWATER MALL, STRUBENS VALLEY, ROODERPOORT, JOHANNESBURG, 1735",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1277249,
        lng: 27.904712,
      },
      store_title: "Sunglass Hut Clearwater Corner",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2779 <br/> <br/> <strong>Address</strong> <br/> SHOP LM061A, 927 CHRISTIAN" +
          " DE WEST RD, CLEARWATER MALL, STRUBENS VALLEY, ROODERPOORT, JOHANNESBURG, 1735",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "A721",
      store_name: "",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1277249,
        lng: 27.905712,
      },
      store_title: "Sunglass Hut Woolworths Clearwater ",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2793<br/> <br/> <strong>Address</strong> <br/>  SHOP LM001, CNR CHRISTIAAN DE WET & HENDRIK POTGIETER STREET," +
          "  CLEARWATER MALL, ROODEPOORT, JOHANNESBURG, 2093",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1443",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762759>021 276 2759" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 69, CNR RIETFONTEIN RD & BENTEL AVE, EAST RAND MALL, BOKSBURG, GAUTENG,  1459",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1832,
        lng: 28.23796,
      },
      store_title: "Sunglass Hut East Rand Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2759 <br/> <br/> <strong>Address</strong> <br/> SHOP 69, CNR RIETFONTEIN" +
          " RD & BENTEL AVE, EAST RAND MALL, BOKSBURG, GAUTENG, 1459",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1662",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762796>021 276 2796" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP F77, CNR WITKOPPEN & WILLIAM NICOL RD, FOURWAYS MALL, FOURWAYS, JOHANNESBURG, GAUTENG, 2055",
      store_city: "Johannesburg",
      positions: {
        lat: -26.0199572,
        lng: 28.0084061,
      },
      store_title: "Sunglass Hut Fourways",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2796 <br/> <br/> <strong>Address</strong> <br/> SHOP F77, CNR WITKOPPEN &" +
          " WILLIAM NICOL RD., FOURWAYS MALL, FOURWAYS, JOHANNESBURG, GAUTENG, 2055",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1470",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762789>021 276 2789" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP HL58, MELROSE ARCH BLVD, MELROSE ARCH PIAZZA, MELROSE NORTH, 2196",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1322559,
        lng: 28.067458,
      },
      store_title: "Sunglass Hut Melrose Arch",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2789 <br/> <br/> <strong>Address</strong> <br/> SHOP HL58, MELROSE ARCH" +
          " BLVD, MELROSE ARCH PIAZZA, MELROSE NORTH, 2196",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1456",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762773>021 276 2773" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP L53, RIVONIA RD, SANDTONT CITY SHOPPING CENTRE, LOWER LEVEL, SANDHURST EXT 3, SANDTON ,2196",
      store_city: "Johannesburg",
      positions: {
        lat: -26.108631,
        lng: 28.0522267,
      },
      store_title: "Sunglass Hut Sandton City Escalator",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2703 <br/> <br/> <strong>Address</strong> <br/> Kiosk K4/19, RIVONIA RD" +
          " SANDTONT CITY SHOPPING CENTRE, LOWER LEVEL, SANDHURST EXT 3, SANDTON ,2196",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1450",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762772>021 276 2772" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP L11A, RIVONIA RD, SANDTON CITY SHOPPING CENTRE,  SANDHURST EXT 3, SANDTON,  2196",
      store_city: "Johannesburg",
      positions: {
        lat: -26.109631,
        lng: 28.0523267,
      },
      store_title: "Sunglass Hut Sandton City Fountain",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2702 <br/> <br/> <strong>Address</strong> <br/> SHOP L11A, RIVONIA RD," +
          " SANDTON CITY SHOPPING CENTRE, SANDHURST EXT 3, SANDTON,  2196",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "29",
      store_name: "",
      store_city: "Johannesburg",
      positions: {
        lat: -26.108631,
        lng: 28.0523267,
      },
      store_title: "Sunglass Hut Woolworths Sandton",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2701 <br/> <br/> <strong>Address</strong> <br/> SHOP U0D / L0D, RIVONIA" +
          " RD, SANDTON CITY SHOPPING CENTRE, SANDHURST, SANDTON,  2196",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1457",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762774>021 276 2774" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP L46, RIVONIA RD, MANDELA SQ. SHOPPING CENTRE, MANDELA SQR., SANDHURST, 2196",
      store_city: "Johannesburg",
      positions: {
        lat: -26.107214,
        lng: 28.054127,
      },
      store_title: "Sunglass Hut Mandela Square",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2774 <br/> <br/> <strong>Address</strong> <br/> SHOP L46, RIVONIA RD," +
          " MANDELA SQ. SHOPPING CENTRE, MANDELA SQR., SANDHURST, 2196",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1452",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762830>021 27 62830" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 45, CNR  BARRAGE RD & ROSSINI BLVD, VAAL MALL, VANDERBIJLPARK, GAUTENG, 1911",
      store_city: "Johannesburg",
      positions: {
        lat: -26.709689,
        lng: 27.8241121,
      },
      store_title: "Sunglass Hut Vaal Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2830 <br/> <br/> <strong>Address</strong> <br/> SHOP 45, CNR  BARRAGE RD &" +
          " ROSSINI BLVD, VAAL MALL, VANDERBIJLPARK, GAUTENG, 1911",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1461",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762762>021 276 2762" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP U36, CNR MODDERFONTEIN RD & RIEBEEK AVE., GREENSTONE SHOPPING CENTRE, GREENSTONE HILL, EDENVALE, JHB, 1610",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1185629,
        lng: 28.140714,
      },
      store_title: "Sunglass Hut Greenstone",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2762 <br/> <br/> <strong>Address</strong> <br/> SHOP U36, CNR" +
          " MODDERFONTEIN RD & RIEBEEK AVE., GREENSTONE SHOPPING CENTRE, GREENSTONE HILL, EDENVALE, JHB, 1610",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1463",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762756>021 276 2756" +
          " </a><br/> <br/> <strong>Address</strong> <br/> SHOP 27, JAN SMUTS AV, LEVEL 2,  DOMESTIC TERMINAL, OR TAMBO INTERNATIONAL AIRPORT, GAUTENG, 1627",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1366728,
        lng: 28.2421459,
      },
      store_title: "Sunglass Hut ORT Domestic Landside Store",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2756 <br/> <br/> <strong>Address</strong> <br/> SHOP 27, JAN SMUTS AV," +
          " LEVEL 2, DOMESTIC TERMINAL, OR TAMBO INTERNATIONAL AIRPORT, GAUTENG, 1627",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1465",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762757>021 276 2757 </a><br>" +
          " <br/> <br/> <strong>Address</strong> <br/> CART DA 3, JAN SMUTS AV, DOMESTIC TERMINAL AIRSIDE, OR TAMBO INTERNATIONAL AIRPORT, GAUTENG, 1627",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1366728,
        lng: 28.2401459,
      },
      store_title: "Sunglass Hut ORT Domestic Airside Kiosk",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2757<br/> <br/> <strong>Address</strong> <br/>  CART DA 3, JAN SMUTS AV," +
          " DOMESTIC TERMINAL AIRSIDE, OR TAMBO INTERNATIONAL AIRPORT, GAUTENG, 1627",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1535",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762758>021 276 2758 </a><br>" +
          " <br/> <br/> <strong>Address</strong> <br/> CART DL7, OR TAMBO INTERNATIONAL AIRPORT, DOMESTIC ARRIVALS (LANDSIDE), JOHANNESBURG, 1627",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1366728,
        lng: 28.2411459,
      },
      store_title: "Sunglass Hut ORT Domestic Landside Kiosk",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2758<br/> <br/> <strong>Address</strong> <br/>  CART DL7, OR TAMBO" +
          " INTERNATIONAL AIRPORT, DOMESTIC ARRIVALS (LANDSIDE), JOHANNESBURG, 1627",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1522",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762828>021 276 2828 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP M42, LETABA & ORPEN ROAD, THE GLEN SHOPPING CENTRE, EASTCLIFF, OAKDENE, JOHANNESBURG, 2190",
      store_city: "Johannesburg",
      positions: {
        lat: -26.2680402,
        lng: 28.0497695,
      },
      store_title: "Sunglass Hut The Glen",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2828 <br/> <br/> <strong>Address</strong> <br/> SHOP M42, LETABA & ORPEN" +
          " ROAD, THE GLEN SHOPPING CENTRE, EASTCLIFF, OAKDENE, JOHANNESBURG, GAUTENG 2190",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1653",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762787>021 276 2787 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP U 65, CNR BEYERS NAUDE & WELTEVREDE ROAD, CRESTA SHOPPING CENTRE, CRESTA, GAUTENG, 2195",
      store_city: "Johannesburg",
      positions: {
        lat: -26.1306158,
        lng: 27.9740346,
      },
      store_title: "Sunglass Hut Cresta",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2787 <br/> <br/> <strong>Address</strong> <br/> SHOP U 65, CNR BEYERS NAUDE" +
          " & WELTEVREDE ROAD, CRESTA SHOPPING CENTRE, CRESTA, GAUTENG, 2195",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1660",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762781>021 276 2781 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP 2218 MAGWA CRESCENT WATERFALL CITY, GAUTENG",
      store_city: "Johannesburg",
      positions: {
        lat: -26.01375,
        lng: 28.10808,
      },
      store_title: "Sunglass Hut Mall of Africa",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2781 <br/> <br/> <strong>Address</strong> <br/> SHOP 2218 MAGWA CRESCENT" +
          " WATERFALL CITY, GAUTENG",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "A720",
      store_name: "",
      store_city: "Johannesburg",
      positions: {
        lat: -26.01475,
        lng: 28.10808,
      },
      store_title: "Sunglass Hut Woolworths Mall of Africa",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2792<br/> <br/> <strong>Address</strong> <br/> SHOP 2027 MAGWA CRESCENT WATERFALL CITY, MAGWA CRESCENT WATERFALL" + " JOHANNESBURG, GAUTENG, 2919",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1645",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762835>021 276 2835 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP 21, CNR AIRPORT & HEIDELBERG RD, THE MALL @CARNIVAL CITY, DALPARK, BRAKPAN, 1541",
      store_city: "Johannesburg",
      positions: {
        lat: -26.2517777,
        lng: 28.3181862,
      },
      store_title: "Sunglass Hut Mall @ Carnival",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2835 <br/> <br/> <strong>Address</strong> <br/> SHOP 21, CNR AIRPORT &" +
          " HEIDELBERG RD, THE MALL @CARNIVAL CITY, DALPARK, BRAKPAN, 1541",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1537",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762780>021 276 2780 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP 4A, WOODMEAD RETAIL PARK, 1 WATERVAL STREET, WOODMEAD EXT 40, JOHANNESBURG, 2191",
      store_city: "Johannesburg",
      positions: {
        lat: -26.0486936,
        lng: 28.092353,
      },
      store_title: "Sunglass Hut Woodmead Outlet",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2780 <br/> <br/> <strong>Address</strong> <br/> SHOP 4A, WOODMEAD RETAIL" +
          " PARK, 1 WATERVAL STREET, WOODMEAD EXT 40, JOHANNESBURG, 2191",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1650",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762824>021 276 2824 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP G109 CORNER OF SWARTKOPPIES AND KLIPRIVIER ROADS,JOHANNESBURG, 2000",
      store_city: "Johannesburg",
      positions: {
        lat: -26.314709,
        lng: 28.0581199,
      },
      store_title: "Sunglass Hut Mall of the South",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2824 <br/> <br/> <strong>Address</strong> <br/> SHOP G109 CORNER OF" +
          " SWARTKOPPIES AND KLIPRIVIER ROADS,JOHANNESBURG, 2000, SOUTH AFRICA",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1539",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762783>021 276 2783 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP L87 CORNER HENDRIK POTGIETER & FURROW ROAD, MULDERSDRIFT, KRUGERSDORP, 1724",
      store_city: "Johannesburg",
      positions: {
        lat: -26.0604648,
        lng: 27.835665,
      },
      store_title: "Sunglass Hut Cradlestone",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2783 <br/> <br/> <strong>Address</strong> <br/> SHOP L87 CORNER HENDRIK" +
          " POTGIETER & FURROW ROAD, MULDERSDRIFT, KRUGERSDORP, 1724",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1655",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762797>021 276 2797 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP L9 RIVONIA ROAD, MORNINGSIDE, GAUTENG, 2196",
      store_city: "Johannesburg",
      positions: {
        lat: -26.082745,
        lng: 28.060063,
      },
      store_title: "Sunglass Hut Morningside Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2797 <br/> <br/> <strong>Address</strong> <br/> SHOP L9 RIVONIA ROAD," +
          " MORNINGSIDE, GAUTENG, 2196",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1652",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762834>021 276 2834 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP 41A MATLOSANA MALL, KLERKSDORP, JOHANNESBURG, GAUTENG, 2570",
      store_city: "North West",
      positions: {
        lat: -26.8498237,
        lng: 26.7047054,
      },
      store_title: "Sunglass Hut Matlosana Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2834 <br/> <br/> <strong>Address</strong> <br/> SHOP 41A MATLOSANA MALL," +
          " KLERKSDORP, JOHANNESBURG, GAUTENG, 2570",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1654",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762811>021 276 2811 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP 26, 1 AUGRABIES AVENUE, WATERFALL MALL, WATERFALL PARK CASHEN EXT. 12, RUSTENBURG, 0300",
      store_city: "North West",
      positions: {
        lat: -25.7074663,
        lng: 27.2521755,
      },
      store_title: "Sunglass Hut Waterfall Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2811 <br/> <br/> <strong>Address</strong> <br/> SHOP 26, 1 AUGRABIES AVENUE," +
          " WATERFALL MALL, WATERFALL PARK CASHEN EXT. 12, RUSTENBURG, 0300",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1462",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762802>021 276 2802 </a>" +
          " <br/> <br/> <strong>Address</strong> <br/> KIOSK A, CNR N4 HWY & MANDELA DR., HIGHVELD MALL, WITBANK, 1035",
      store_city: "Witbank",
      positions: {
        lat: -25.8871387,
        lng: 29.2637958,
      },
      store_title: "Sunglass Hut Highveld Kiosk",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2802<br/> <br/> <strong>Address</strong> <br/>  KIOSK A, CNR N4 HWY &" +
          " MANDELA DR., HIGHVELD MALL, WITBANK, 1035",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1506",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762821>021 276 2821 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP U95A, MALL OF THE NORTH, CNR R81 AND N1 BENDOR EXTENSION 99, POLOKWANE, LIMPOPO, 0699",
      store_city: "Polokwane (Limpopo)",
      positions: {
        lat: -23.8736257,
        lng: 29.5094695,
      },
      store_title: "Sunglass Hut Mall of the North",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2821 <br/> <br/> <strong>Address</strong> <br/> SHOP U95A, MALL OF THE" +
          " NORTH, CNR R81 AND N1 BENDOR EXTENSION 99, POLOKWANE, LIMPOPO, 0699",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1538",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762842>021 276 2842 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP 11B, MAIN ROAD, WALMER PARK SHOPPING CENTRE, WALMER PARK, PORT ELIZABETH,6001",
      store_city: "Port Elizabeth",
      positions: {
        lat: -33.9802012,
        lng: 25.5589952,
      },
      store_title: "Sunglass Hut Walmer Park",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2842 <br/> <br/> <strong>Address</strong> <br/> SHOP 11B, MAIN ROAD, WALMER" +
          " PARK SHOPPING CENTRE, WALMER PARK, PORT ELIZABETH,6001",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1536",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762845>021 276 2845 </a>" +
          " <br/> <br/> <strong>Address</strong> <br/> KIOSK, ALISSTER MILLER DRIVE, PORT ELIZABETH AIRPORT - DOMESTIC DEP. (AIRSIDE), WALMER, PORT ELIZABETH, 6065",
      store_city: "Port Elizabeth",
      positions: {
        lat: -33.9872719,
        lng: 25.6142947,
      },
      store_title: "Sunglass Hut Port Elizabeth Airport",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2845<br/> <br/> <strong>Address</strong> <br/>  KIOSK, ALISSTER MILLER" +
          " DRIVE, PORT ELIZABETH AIRPORT - DOMESTIC DEP. (AIRSIDE), WALMER, PORT ELIZABETH, 6065",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1646",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762846>021 276 2846 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP UG101 WALKER DRIVE PORT ELIZABETH 6025",
      store_city: "Port Elizabeth",
      positions: {
        lat: -33.951804,
        lng: 25.461136,
      },
      store_title: "Sunglass Hut Baywest",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2846 <br/> <br/> <strong>Address</strong> <br/> SHOP UG101 WALKER DRIVE PORT" +
          " ELIZABETH 6025",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1448",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762805>021 276 2805 </a>" +
          " <br/> <br/> <strong>Address</strong> <br/> SH 214A, CNR ATTERBURY RD & LOIS AVE, MENLYN PARK SHOPPING CENTRE,  MENLO PARK, PRETORIA, 0081",
      store_city: "Pretoria",
      positions: {
        lat: -25.7826769,
        lng: 28.2762908,
      },
      store_title: "Sunglass Hut Menlyn",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2805 <br/> <br/> <strong>Address</strong> <br/> SHOP 214A, CNR ATTERBURY RD" +
          " & LOIS AVE, MENLYN PARK SHOPPING CENTRE, MENLO PARK, PRETORIA, 0081",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1447",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762804>021 276 2804 </a>" +
          "<br/> <br/> <strong>Address</strong> <br/> SH G111, CNR ATTERBURY RD & LOIS AVE, MENLYN PARK SHOPPING CENTRE,  MENLO PARK, PRETORIA, 0081",
      store_city: "Pretoria",
      positions: {
        lat: -25.7826769,
        lng: 28.2760908,
      },
      store_title: "Sunglass Hut Menlyn Escalator",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2804 <br/> <br/> <strong>Address</strong> <br/> SHOP G111, CNR ATTERBURY RD" +
          " & LOIS AVE, MENLYN PARK SHOPPING CENTRE, MENLO PARK, PRETORIA, 0081",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "A719",
      store_name: "",
      store_city: "Pretoria",
      positions: {
        lat: -25.7826769,
        lng: 28.2763908,
      },
      store_title: "Sunglass Hut Woolworths Menlyn ",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2790<br/> <br/> <strong>Address</strong> <br/>  SH G6, CNR ATTERBURY RD & LOIS AVE," +
          " MENLYN PARK SHOPPING CENTRE, MENLO PARK, PRETORIA, 0082",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "34",
      store_name: "",
      store_city: "Pretoria",
      positions: {
        lat: -25.7717278,
        lng: 28.2346988,
      },
      store_title: "Sunglass Hut Brooklyn",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2815 <br/> <br/> <br/> <strong>Address</strong> <br/> SHOP 212, 338 BRONKHORST ST," +
          " BROOKLYN MALL, NEW MUCKLENEUK, PRETORIA, 0181",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1492",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762808>021 276 2808 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP 3.09, CNR ATTERBURY RD & SELIKATS CAUSEWAY, ATTERBURY VALUE MART, FAERIE GLEN, PRETORIA, 0043",
      store_city: "Pretoria",
      positions: {
        lat: -25.7886207,
        lng: 28.3078087,
      },
      store_title: "Sunglass Hut Atterbury Outlet",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2808 <br/> <br/> <strong>Address</strong> <br/> SHOP 3.09, CNR ATTERBURY RD" +
          " & SELIKATS CAUSEWAY, ATTERBURY VALUE MART, FAERIE GLEN, PRETORIA, 0043",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "A722",
      store_name: "",
      store_city: "Pretoria",
      positions: {
        lat: -25.857243,
        lng: 28.18782,
      },
      store_title: "Sunglass Hut Woolworths Centurion",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2794<br/> <br/> <strong>Address</strong> <br/>  CNR ATTERBURY RD & SELIKATS" +
          " CAUSEWAY, ATTERBURY VALUE MART, FAERIE GLEN, PRETORIA, 0043",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "1505",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762809>021 276 2809 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP 274, CNR OF GARSFONTEIN & DEVILLEBOIS DRIVE, WOOLANDS BOULEVARD, WOODHILL, PRETORIA, 0077",
      store_city: "Pretoria",
      positions: {
        lat: -25.8227999,
        lng: 28.31241,
      },
      store_title: "Sunglass Hut Woodlands Boulevard",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2809 <br/> <br/> <strong>Address</strong> <br/> SHOP 274, CNR OF GARSFONTEIN" +
          " & DEVILLEBOIS DRIVE, WOODLANDS BOULEVARD, WOODHILL, PRETORIA, 0077",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1659",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762785>021 276 2785 </a>" +
          " <br/> <br/> <strong>Address</strong> <br/> SHOUP U15. CNR OF DR. V/D MERWE & ZAMBEZI DRIVE, KOLLONADE SHOPPING CENTRE, MONTANA PARK, PRETORIA, 0159",
      store_city: "Pretoria",
      positions: {
        lat: -25.6814909,
        lng: 28.251408,
      },
      store_title: "Sunglass Hut Kolonnade Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2785<br/> <br/> <strong>Address</strong> <br/>  SHOP U15, CNR OF DR. V/D" +
          " MERWE & ZAMBEZI DRIVE, KOLONNADE SHOPPING CENTRE, MONTANA PARK, PRETORIA, 0159",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "1541",
      store_name:
          "<strong>Tel. Number:</strong> <br/> <a href=tel:0212762770>021 276 2770 </a><br>" +
          " <br><br/> <br/> <strong>Address</strong> <br/> SHOP 432, THEGROVE MALL OF NAMIBIA, WINDHOEK, NAMIBIA",
      store_city: "Windhoek",
      positions: {
        lat: -22.619112,
        lng: 17.094296,
      },
      store_title: "Sunglass Hut Mall of Namibia",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2770 <br/> <br/> <strong>Address</strong> <br/> SHOP 432, THEGROVE MALL OF" +
          " NAMIBIA, WINDHOEK, NAMIBIA",
      store_storelink: "",
      store_image:
          "catalog/brandstore/ruosh/logo/imagefiles_location_map_pin_black5.png",
      store_store_img: "",
      store_store_type: 0,
      store_status: "enable",
      store_distance: "",
      store_identity: 39,
    },
    {
      store_id: "35",
      store_name: "",
      store_city: "Johannesburg",
      positions: {
        lat: -26.18,
        lng: 28.03,
      },
      store_title: "Sunglass Hut Hyde Park Kiosk",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2791 <br/> <br/> <strong>Address</strong> <br/> SHOP 53A, JAN SMUTS AVE EXT" +
          " 16, HYDE PARK SHOPPING CENTRE UPPER LEVEL, HYDE PARK, GAUTENG, 2196",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "36",
      store_name: "",
      store_city: "Nelspruit (Mpumalanga)",
      positions: {
        lat: -25.4689702,
        lng: 30.957685,
      },
      store_title: "Sunglass Hut Ilanga Mall",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2801 <br/> <br/> <strong>Address</strong> <br/> SHOP UG33 BITTERBESSIE ST," +
          " WEST ACRES, NELSPRUIT, 1200",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
    {
      store_id: "31",
      store_name: "",
      store_city: "Johannesburg",
      positions: {
        lat: -26.12995692,
        lng: 27.97396971,
      },
      store_title: "Sunglass Hut Woolworths Cresta",
      store_address:
          "<strong>Tel. Number:</strong> <br/> 021 276 2826 <br/> <br/> <strong>Address</strong> <br/> SHOP U 18, CNR BEYERS NAUDE & WELTEVREDE ROAD," +
          " CRESTA SHOPPING CENTRE, CRESTA, GAUTENG, 2195",
      store_storelink: "",
      store_image: "",
      store_store_img: "",
      store_store_type: null,
      store_status: "",
      store_distance: "",
      store_identity: null,
    },
  ],
};
